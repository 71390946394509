const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://rawby1x5d5.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://x3lrs25klb.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://ft0uk1o8o1.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.staging.wecomplai.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.wecomplai.forwoodsafety.com',
    WEBSOCKET: 'wss://ko46w5f5id.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;